.header {
  background-color: #464D69;
  color: white;
  padding: 40px 50px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: white;
  padding: 20px;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
}

.subtitle {
  display: flex;
  justify-content: center;
}

.select {
  margin: 10px 0;
}

.formControlLabel {
  margin: 0;
}

.buttons {
  margin-left: auto;
  display: flex;
  gap: 10px;
}