/*=========== Background Utilities ==========*/

.bg-aqua {
  background-color: $aqua;
}

.bg-light {
  background-color: $gray-200 !important;
}

.bg-grdnt-youtube {
  background: linear-gradient(0deg, #F5AF19 0%, #F12711 100%);
}

.bg-grdnt-primary-danger {
  background: linear-gradient(0deg, #EC2F4B 0%, #009FFF 100%);
}

.bg-grdnt-purple-danger {
  background: linear-gradient(0deg, #240B36 0%, #C31432 100%);
}