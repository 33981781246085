
.circle {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  border: 4px solid #fff;
  border-radius: 50%;
  min-height: 56px;
  height: 56px;
  min-width: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.line {
  position: absolute;
  height: 4px;
  width: 50%;

}

.inactiveLine {
  position: absolute;
  height: 4px;
  width: 50%;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.completedLine {
  position: absolute;
  height: 4px;
  width: 50%;
  top: 50%;
  transform: translate(0, -50%);
}