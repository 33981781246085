/*========== Widgets scss =========*/

//Flex chart
.flex-chart {
  .rct-block-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

//To Do List widget
.to-do-list {
  ul {
    > div {
      &:hover {
        .hover-action {
          opacity: 1;
        }
      }
    }
  }
}

//New Mail widget
.hover-action {
  opacity: 0;
  transition: all 0.4s linear 0s;

  button,
  a {
    height: 34px !important;
    width: 34px !important;
    font-size: 1.3rem;
  }
}

.comment-section {
  ul {
    > div {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }

      .comment-date {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        .hover-action {
          opacity: 1;
        }

        .comment-date {
          opacity: 0;
        }
      }

      @media(max-width: 500px) {
        display: block !important;
        .comment-action {
          width: 100% !important;
          text-align: left !important;
        }
      }
    }
  }
}

// Weather widget
.weather-top {
  position: relative;

  .weather-over {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    padding: 0.625rem 1.875rem; //10px 30px;
  }

  .weather-head,
  .weather-temp {
    color: $white;
  }

  .weather-temp {
    h2 {
      font-size: 3.75rem;
      font-weight: 600;
      margin: 0;
    }
  }

  .weather-head {
    h4 {
      font-size: 1.5rem; //24px;
      font-weight: 500;
    }

    i {
      font-size: 5rem;
    }
  }
}

// Tabed Notification Widget
.notification-box {
  border: none;

  ul {
    li {
      h5 {
        color: rgba(0, 0, 0, 0.87);
      }

      margin-bottom: 1.25rem; //20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.message-head {
  color: $gray-600;

  i {
    font-size: 1rem;
    margin-right: 5px;
  }
}

// New Customer
.small-btn {
  width: 33px !important;
  height: 30px !important;
  font-size: 1rem !important;
}

.new-customer-list {
  li {
    button {
      font-size: 1.1rem;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 100%;
      padding: 0;
      margin: 3px;
      background-color: transparent;
      border: 0;

      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }
    }
  }
}

// Recent Activity

//support request

//stock exchange
.stock-exchange {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      @include border(1px solid, $input-border-color, bottom);
      padding: 0.9375rem;
    }
  }
}

//share friend
.share-friend {
  .social-share {
    li {
      padding: 0;
      margin-right: 1rem;

      &:last-child {
        margin: 0;
      }

      a {
        color: $white;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
      }
    }
  }
}

//Quote Of the Day
.review-slider {
  .rct-block-title {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);

    h4 {
      color: $white;
    }
  }

  .slick-dots {
    text-align: left;
    padding-left: 1rem;
    bottom: 0;

    li {
      margin: 0;

      button {
        &:before {
          color: $white;
          font-size: 0.5rem;
        }
      }
    }
  }
}

//Blog Layout Three
.blog-layout-three {
  overflow: hidden;

  .blog-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%);
  }
}

//User Profile

//Latest Post
.blog-list-wrap {
  ul {
    .post-item {
      .post-img {
        width: 4rem;
      }

      &:hover {
        .hover-action {
          opacity: 1;
        }
      }
    }
  }
}

//Project Management
.project-managemnet-wrapper {
  .table {
    .progress {
      height: 0.3125rem;
      border-radius: 2rem;

      .progress-bar {
        border-radius: 2rem;
      }
    }
  }
}

//task-management-wrapper
.task-management-wrapper {
  .project-heading {
    padding: 0.5rem 1.5rem;
  }

  .task-title {
    width: 60%;

    h4 {
      word-wrap: break-word;
      color: $gray-600;
    }
  }
}

//Team Avatar

//Activity Board
.activity-board-wrapper {
  .comment-box {
    background-color: $gray-100;
    @include border(1px solid, $input-border-color, top bottom left right);
    padding: 0.625rem 1.875rem 0.625rem 0.625rem;
  }

  .attachment-wrap {
    .list-inline-item {
      .overlay-content {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.7);
        transition: opacity 0.5s ease-in-out;
      }

      &:hover {
        .overlay-content {
          opacity: 1;
        }
      }
    }
  }

  .progress {
    height: 0.75rem;
    border-radius: 2.5rem;

    .progress-bar {
      border-radius: 2.5rem;
    }
  }
}

.list-group-item {
  border-color: $border-color;
}

//Trending News Widget

//Visitors Widget

//newslater widget

//Subscribers Widget

/* ============== Social Share Widget ====================*/

.social-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: $block-shadow;
  border-radius: $block-border-radius;
  margin-bottom: $block-margin-bottom;
  transition: $block-transition;
  padding: $block-spacing;
  background-color: $block-bg;

  span {
    display: block;
  }

  > span {
    width: 40%;
    text-align: center;

    &:last-child {
      @include border(1px dashed, $border-color, left);
    }
  }

  &:hover {
    box-shadow: $block-hover-shadow;

  }
}

/*==================== Top author ================*/

/*============= Top news wrap ==============*/

/*================== Top Headlines widget============*/

/*================= Twitter Feed V2 ==================*/

/*========= Responsive ==========*/

@media (max-width: 1300px) and (min-width: 320px) {
  .promo-coupon img {
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 500px) and (min-width: 320px) {
  .project-managemnet-wrapper {
    .table thead tr th:last-child span {
      display: none;
    }
  }
  .blog-list-wrap {
    .post-item,
    .post-item .post-content {
      display: block !important;

      .post-img {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 1.875rem;
      }
    }
  }
}

// ----- Define IE hacks -----
@media all and (-ms-high-contrast: none) {
  .ie-flex {
    li {
      .media .media-body {
        flex: inherit;
      }
    }
  }
  select::-ms-expand {
    display: none;
  }
  .card {
    display: block;
  }
}

//line chart widget
.bg-color {
  .rct-block {
    * {
      color: #fff;
    }
  }

  .chart-title {
    span {
      font-size: 1.375rem;
    }
  }
}

.primary-chart {
  .rct-block {
    background-color: $primary;
  }
}

.success-chart {
  .rct-block {
    background-color: $success;
  }
}

.info-chart {
  .rct-block {
    background-color: $info;
  }
}

.danger-chart {
  .rct-block {
    background-color: $danger;
  }
}

//---- upcoming events ----

//------ ongoing-projects-wrap ------
.ongoing-projects-wrap {
  .project-list {
    li {
      margin-bottom: 8px;

      i {
        font-size: 22px;
        color: $primary;
      }

      span {
        text-transform: capitalize;
      }
    }
  }
}

.progress-xs {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
}

.notification-v2-wrap {
  .text-base {
    span {
      color: #A5A7B2 !important;
      font-weight: bold !important;
      font-size: 1rem !important;
    }
  }
}

.live-support-wrap {
  ul {
    li {

      .content {
        width: calc(100% - 65px);
      }
    }
  }

  .chat-form {
    > div {
      width: calc(100% - 100px);
    }
  }
}

@media (max-width: 575px) {
  .live-support-wrap {
    .chat-form {
      > div {
        width: calc(100% - 50px);
      }

      button {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media (max-width: 374px) {
  .live-support-wrap {
    ul {
      li {
        > div {
          display: block !important;
        }

        .content {
          padding: 20px 0 0 !important;
          width: 100%;
        }
      }
    }
  }
}