/*====== Warning Theme ======*/

body.theme-warning {
  .text-primary,
  .breadcrumb a,
  .dropdown-foot a,
  .faq-page-wrapper .panel-heading h4,
  .agency-menu .nav .nav-item:hover > .nav-link,
  .app-boxed .app-container .rct-page .agency-menu .nav .nav-item:hover > .nav-link > span {
    color: $warning !important;
  }

  .badge-primary,
  .bg-primary,
  .btn-primary,
  {
    * {
      color: $white !important;
    }
  }

  .quicklink-wrapper .dropdown-menu:after,
  .quicklink-wrapper .dropdown-menu:before,
  .sidebar-user-block .rct-dropdown.show .dropdown-menu:before,
  .sidebar-user-block .rct-dropdown.show .dropdown-menu:after {
    border-bottom-color: $warning !important;
  }

  .header-icon:hover,
  .header-icon:focus {
    border-color: $white !important;
  }

  //Color Switching
  .text-warning {
    color: $primary !important;
  }

  .badge-warning,
  .bg-warning,
  .btn-warning {
    background: $primary !important;
  }

  .btn-warning {
    border-color: $primary !important;
  }
}