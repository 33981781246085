/*======== Header Scss ========*/

.app-header {
  .rct-header {
    background-color: $navbar-bg;
    z-index: 0;
    position: relative;
    box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2) !important;

    .site-logo {
      padding: 1rem 4rem 0.8rem 2rem;
      background-color: $primary;
    }

    .navbar-left {
      margin-left: 0.85rem;
    }

  }
}

.header-icon {
  height: $navbar-icon-height;
  width: $navbar-icon-width;
  @include border(1px solid, $input-border-color, top bottom left right);
  border-radius: $border-radius;
  line-height: $navbar-icon-height;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem; //20px;
  transition: all 0.5s ease 0s;
  color: $gray-600;

  &:hover,
  &:focus,
  &.active {
    border-color: $primary !important;
    color: $primary !important;

    a {
      color: $primary;
    }
  }
}

//Cart Dropdown

//Notification-wrap

.humburger {
  color: $hamburger-icon-color !important;
  border: 1px solid $input-border-color !important;
  height: 2.5rem !important;
  width: 2.5rem !important;
}

@media (max-width: 1199px) and (min-width: 320px) {
  .rct-header {
    &.mui-fixed {
      left: 0;
    }
  }
  .rct-app-content {
    width: 100% !important;
  }
}

/*========= Dashboard Overlay ======*/

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.show {
  -webkit-animation-name: show;
  animation-name: show;
}

.dashboard-overlay {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  padding: 1.875rem; //30px;
  height: 100vh;
  top: 63px;
  z-index: 99;
  overflow-y: auto;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  .dashboard-overlay-content {
    color: $gray-900;

    .totle-status {
      h2 {
        font-size: 1.5rem; //24px;
      }
    }
  }

  .table {
    tr {
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .progress {
    height: 6px;
  }

  .rating-star {
    li {
      font-size: 1.875rem; //30px;
    }
  }

  .report-title {
    background: $gray-100;
    font-size: 0.875rem; //14px;
    padding: 0.9375rem; //15px;
    margin-bottom: 0;
  }

  .overlay-head {
    padding: 0 0.9375rem; //15px;
    .closed {
      font-size: 1.375rem; //22px;
      color: $white;
      font-weight: 700;
    }
  }
}

/*======== Search Form =======*/

/*======= Responsive ========*/

@media (max-width: 500px) {
}

@media (min-width: 725px) {
  .search-wrapper {
    display: block !important;
  }
}

@media (max-width: 724px) {
  .app-horizontal {
    .app-header {
      .site-logo {
        padding: 0.875rem 1rem !important;
      }

      .navbar-right {
        margin: 0 0 0 -1.25rem;
      }
    }
  }
}

@media (max-width: 991px) {
  .app-main-container {
    > div {
      > div {
        box-shadow: none !important;
      }
    }
  }
}

@media (max-width: 450px) {
}

//latest css
.rct-link-btn {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}