//--- Font size ---//
.font-lg {
  font-size: $font-size-lg;
}

.font-sm {
  font-size: $font-size-sm !important;
}

.font-xs {
  font-size: 0.75rem !important;
  line-height: 1.25rem;
}

.font-3x {
  font-size: 3rem;
}

.font-4x {
  font-size: 4rem !important;
}

.font-5x {
  font-size: 5rem;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

//Font weight
.fw-light {
  font-weight: 300 !important;
}

.fw-semi-bold {
  font-weight: $fw-semi-bold !important;
}

.fw-bold {
  font-weight: $fw-bold !important;
}

// Font Text
.text-pink {
  color: $pink !important;
}

.text-teal {
  color: $teal !important;
}

.text-purple {
  color: $purple !important;
}

.text-yellow {
  color: $orange !important;
}

.text-gray {
  color: $gray-700;
}

.text-base {
  color: #A5A7B2;
}

.facebook {
  color: $facebook;
}

.twitter {
  color: $twitter;
}

.google {
  color: $google;
}